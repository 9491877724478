import logo from './logo.svg';

import { useState } from 'react';



function getExcuse(receiver, keywords, storyType) {
  return fetch(`https://zerotime.dev/generate?receiver=${receiver}&keywords=${keywords}&story_type=${storyType}`).then(r => r.json());
}

function App() {

  const [receiver, setReceiver] = useState("")
  const [keywords, setKeywords] = useState("")
  const [storyType, setStoryType] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)
  const [excuse, setExcuse] = useState("")

  async function onExcuse() {
    // API call goes here
    setShowSpinner(true)
    const excuse = await getExcuse(receiver, keywords, storyType);
    setShowSpinner(false)
    setExcuse(excuse.text)
  }

  return (
    <main className="App ">
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-32'>

        <div className='info-bar p-10 m-5'>

          <h1 className='text-2xl text-slate-900 text-4xl tracking-tight font-extrabold sm:text-5xl dark:text-white'>
            Get more time in the day with our amazing Productivity tool
          </h1>
          <div className='text-lg	 mt-4 text-lg text-gray-500 dark:text-gray-300 mt-20 pt-6 px-4 sm:px-6 lg:px-8'>
            Are you tired of wasting time on unimportant tasks? Do you want to get more done in less time? Then you need our amazing productivity tool. It will help you get more done in less time.
          </div>
        </div>
        <div className='form bg-blue rounded-lg py-10 text-xl text-white'>

          <div className='question'>
            <label htmlFor="receiver">
              Who is getting in your way?
            </label>
            <input className='ml-5 input input-bordered w-full text-black	 max-w-xs' name="receiver" placeholder="Name of your manager" type="text" value={receiver} onChange={(evt) => setReceiver(evt.target.value)} />
          </div>

          <div className='mt-3 question'>
            <label htmlFor="keywords">
              What are your favorite things?
            </label>
            <input className='ml-5 input input-bordered w-full text-black	 max-w-xs' name="keywords" placeholder="meatballs,clouds" value={keywords} onChange={(evt) => setKeywords(evt.target.value)} />
          </div>

          <div className='mt-3 question'>
            <label htmlFor="receiver">
              let us know your emotional states?
            </label>
            <input className='ml-5 input input-bordered w-full text-black	 max-w-xs' name="storyType" placeholder="aggresive, confusing, intimidating" type="text" value={storyType} onChange={(evt) => setStoryType(evt.target.value)} />
          </div>


          <button className={`btn bg-green hover:bg-green-500 text-2xl mt-4 ${showSpinner ? 'loading' : ''}`} onClick={onExcuse}>Get your time back</button>

          <div className="excuse whitespace-pre-line p-8 max-w-lg m-auto">
            {excuse}
          </div>

        </div>
      </div>

      {/* <div class="area" > */}
      {/* <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul> */}
      {/* </div > */}
    </main>
  );
}

export default App;
